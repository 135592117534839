@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-extraleicht.woff2") format("woff2");
  font-weight: 200; /* font-extralight */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-leicht.woff2") format("woff2");
  font-weight: 300; /* font-light */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-buch.woff2") format("woff2");
  font-weight: 400; /* font-normal */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-kraftig.woff2") format("woff2");
  font-weight: 500; /* font-medium */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-halbfett.woff2") format("woff2");
  font-weight: 600; /* font-semibold */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-dreiviertelfett.woff2") format("woff2");
  font-weight: 700; /* font-bold */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-fett.woff2") format("woff2");
  font-weight: 800; /* font-extrabold */
  font-style: normal;
}
@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Sohne/soehne-extrafett.woff2") format("woff2");
  font-weight: 900; /* font-black */
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf")
    format("tff");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  /* BASE STYLES */
  #root {
    @apply flex min-h-screen w-full flex-col font-sohne text-sm leading-normal;
  }

  img {
    @apply pointer-events-none;
  }

  a {
    outline: none;
  }

  button {
    /* outline: none; */
    @apply transition-all duration-200 ease-in-out;
  }

  .bg-figure {
    @apply bg-gray-50 bg-fig-bg-new bg-cover bg-[top_0px_right_-230px] bg-no-repeat
    tablet:bg-left
    laptop:bg-left;
  }

  /* Common classes for normal flex */

  .flex-common {
    @apply flex flex-col justify-between laptop:flex-row;
  }

  /********************** Test Colours **********************/
  .text-primary {
    @apply text-gray-900 dark:text-gray-100;
  }

  .text-gray {
    @apply text-gray-600 dark:text-gray-400;
  }

  .text-link {
    @apply text-brand-lightblue dark:text-brand-lightblue;
  }

  .text-sub {
    @apply text-gray-700 dark:text-gray-300;
  }

  /* SURFACE COLOURS */
  .surface-primary {
    @apply bg-white dark:bg-neutral-900;
  }

  .surface-secondary {
    @apply bg-gray-100 dark:bg-zinc-800;
  }

  .surface-border-primary {
    @apply bg-gray-200 dark:bg-zinc-800;
  }

  .surface-border-secondary {
    @apply bg-gray-300 dark:bg-neutral-800;
  }

  .surface-db-stepper {
    @apply bg-gray-50;
  }

  .btn-disabled {
    @apply bg-gray-200;
  }

  /********************** Scrollbar Styling **********************/
  /* Add momentum to scrolling */
  .scrolling-wrapper {
    -webkit-overflow-scrolling: touch;
  }

  /* width */
  .scrolling-wrapper::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .scrolling-wrapper::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  .scrolling-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #888;
  }

  /* Handle on hover */
  .scrolling-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /********************** Home Layout **********************/
  /* .home-container {
    @apply mx-auto flex w-full flex-col gap-[80px] px-[40px] py-[80px] tablet:w-[565px] tablet:py-[128px] laptop:w-[950px] laptop:justify-between laptop:px-0 desktop:w-[1115px];
  } */

  .home-hero {
    @apply mx-auto flex w-full flex-col justify-between tablet:-mt-[20px]
    tablet:max-w-[1205px] tablet:overflow-clip tablet:px-[60px] tablet:pb-[30px] laptop:max-w-[1600px]
    laptop:flex-row laptop:px-[190px] laptop:pb-[40px];
  }

  .home-content {
    @apply flex w-full flex-col justify-between gap-[40px] px-6 py-[80px] tablet:py-[130px]
    laptop:flex-row
    laptop:px-[190px];
  }

  .title {
    @apply /*text-[32px] laptop:text-[60px]*/ text-left font-semibold leading-tight tablet:text-[48px];
  }

  .subtitle-home {
    @apply text-center text-[32px] font-semibold leading-tight laptop:text-[40px];
  }

  .bg-figure-home {
    @apply bg-fig-1920 bg-cover bg-right bg-no-repeat
    laptop:bg-center;
  }

  .bg-pricing {
    @apply bg-gradient-to-l from-[#BEE9FD] via-[#E8F7FE] to-white
      tablet:bg-fig-pricing tablet:bg-cover tablet:bg-right-top tablet:bg-no-repeat;
  }

  /********************** Auth Layouts **********************/

  .form-container {
    @apply flex w-full flex-col justify-between gap-8 rounded-xl border border-gray-200 bg-white p-8 shadow-lg 
           tablet:w-[950px] tablet:flex-row tablet:gap-16
           laptop:w-[1150px];
  }

  /********************** Legal Layouts **********************/

  /* Legal container */
  .legal-content {
    @apply flex w-full flex-row justify-between px-7 lg:px-18 xl:w-[1280px] xl:mx-auto;
  }

  .legal-page-bg {
    @apply h-[500px] bg-fig-1920 bg-cover bg-[top_0px_right_-90px] bg-no-repeat
    tablet:bg-right
    laptop:bg-center;
  }

  .title-legal {
    @apply text-[32px] font-semibold text-black tablet:text-[38px];
  }

  /* Add custom styles for the ordered list */
  ol.alphabet-list {
    list-style-type: none;
    counter-reset: alphabet-counter;
  }

  ol.alphabet-list > li {
    display: flex;
  }

  ol.alphabet-list li::before {
    display: inline-block;
    flex-shrink: 0;
    content: "(" counter(alphabet-counter, lower-alpha) ") ";
    counter-increment: alphabet-counter;
    padding-right: 0.5rem;
    width: 50px;
  }

  ol.roman-list {
    list-style-type: none;
    counter-reset: item;
  }

  ol.roman-list > li {
    display: flex;
  }

  ol.roman-list > li::before {
    display: inline-block;
    flex-shrink: 0;
    content: "(" counter(item, lower-roman) ")";
    counter-increment: item;
    padding-right: 0.5rem;
    width: 50px;
  }

  ol.sub-alphabet-list {
    list-style-type: none;
    counter-reset: alphabet-counter;
  }

  ol.sub-alphabet-list > li {
    display: flex;
  }

  ol.sub-alphabet-list li::before {
    display: inline-block;
    flex-shrink: 0;
    content: counter(alphabet-counter, lower-alpha) ".";
    counter-increment: alphabet-counter;
    padding-right: 0.5rem;
    width: 20px;
  }
}

@layer components {
  /** BUTTONS **/
  .btn {
    @apply relative flex flex-row items-center justify-center gap-2 rounded-[10px] disabled:cursor-not-allowed disabled:opacity-50;
  }

  .btn-green {
    @apply bg-[#DCFCE7] text-[#15803D] focus:ring-4 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-transparent enabled:hover:bg-[#15803D] enabled:hover:text-[#DCFCE7] enabled:hover:shadow-md;
  }

  .btn-primary {
    @apply btn bg-gray-900 text-white outline-none focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-800 enabled:hover:shadow-md disabled:opacity-50;
  }

  .btn-white {
    @apply btn text-gray-900 h-fit w-full gap-2 border border-gray-200 bg-white px-3 py-2 hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-white lg:w-fit;
  }
}

@layer utilities {
  /* HONEY FORM */
  .honey {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
}
