@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Elements */
  #partner-page {
    @apply font-inter;
  }

  img {
    @apply pointer-events-none;
  }

  a {
    @apply text-dark font-medium hover:text-gray-900;
  }

  /* Marketing styles (Responsive) */

  /* Typography */
  /* .heading-global {
    @apply leading-none tracking-tight;
  } */

  /* .body-global {
    @apply text-gray font-normal leading-normal tracking-normal;
  } */

  /* Class to apply to H1 for Hero sections
    // requested by Pete but turns out h1 is enough. just added break-all class to all h1 with .h0 applied */
  /* .h0 { 
    @apply tablet:text-5xl desktop:text-6xl heading-global text-gray-900 font-semibold break-all;
    } */

  .h1 {
    @apply text-gray-900 text-5xl xl:text-6xl font-semibold leading-none tracking-tight;
  }

  .h2 {
    @apply text-gray-900 text-3xl xl:text-[44px] font-semibold leading-none tracking-tight;
  }

  .h3 {
    @apply text-2xl xl:text-[32px] font-semibold leading-tight tracking-tight;
  }

  .h4 {
    @apply text-xl md:text-lg xl:text-2xl font-medium leading-tight tracking-tight;
  }

  .h5 {
    @apply md:text-sm xl:text-xl font-medium leading-tight tracking-tight;
  }

  /* .h6 {
    @apply text-sm font-medium md:text-base xl:text-lg;
  } */

  .btn-text {
    @apply text-sm font-medium leading-normal tracking-normal md:text-base lg:text-lg;
  }

  .body-lg {
    @apply !text-black md:text-gray text-base md:text-sm xl:text-lg font-extralight leading-normal tracking-normal;
  }

  .body-md {
    @apply !text-black md:text-gray text-base md:text-sm xl:text-base font-extralight leading-normal tracking-normal;
  }

  .body-sm {
    @apply !text-black md:text-gray text-xs xl:text-sm font-extralight leading-normal tracking-normal;
  }
  .body-xs {
    @apply !text-black md:text-gray text-xs font-extralight leading-normal tracking-normal;
  }

  /* .p {
    @apply text-sm leading-normal md:text-sm 2xl:text-base;
  } */

  /* Apply to paragraphs to highlight */
  .pSpecial {
    @apply text-dark font-medium;
  }

  .testimonialText {
    @apply text-xs leading-normal;
  }

  .span {
    @apply leading-none;
  }

  /* Layout Spacing */

  /* Determines the width of the content box */
  /* .w-responsive {
        @apply w-full laptop:max-w-screen-lg desktop:max-w-screen-xl;
        @apply w-full desktop:max-w-screen-xl mx-auto;
    } */

  .w-responsive {
    @apply flex w-full px-5 md:px-0 md:w-[688px] xl:w-[980px] xl:mx-auto;
    /* @apply flex px-6 tablet:px-20 laptop:max-w-[1600px] laptop:px-[120px] desktop:mx-auto; */
  }

  .layout-y {
    @apply py-24 md:py-40;
  }

  .layout-x {
    @apply px-7 tablet:px-18 laptop:px-40 desktop:mx-auto desktop:px-0;
  }

  .full-layout-y {
    @apply py-16 tablet:py-24;
  }

  .hero-y {
    @apply py-0 tablet:py-24;
  }

  /* Use for between text elements or inside a panel */
  .gap-sm-c {
    @apply gap-7 xl:gap-10;
  }

  /* Use for between two panels inside a section */
  .gap-md-c {
    @apply gap-12 md:gap-16 xl:gap-24;
  }

  /* Use for between two sections in a page */
  .gap-lg-c {
    @apply gap-24 md:gap-36 xl:gap-40;
  }

  /* Use for normal panel padding for boxed content */
  .p-sm-c {
    @apply p-7 xl:p-10;
  }

  /* Use for big panel padding like CTA */
  .p-md-c {
    @apply p-8 md:p-10 xl:p-12;
  }

  /* Use for big panel padding like CTA */
  .p-lg-c {
    @apply p-10 md:p-24 xl:p-[128px];
  }

  /* TEXT COLOURS */
  .text-primary {
    @apply text-gray-900;
  }

  .text-secondary {
    @apply text-gray-800;
  }

  .text-sub {
    @apply text-gray-700;
  }

  .text-icons {
    @apply text-gray-400;
  }

  .text-black {
    @apply text-['black'];
  }

  .text-white {
    @apply text-['white'];
  }

  .text-gray {
    @apply text-black md:text-slate-600;
  }

  .text-label {
    @apply text-neutral-700;
  }

  .text-body {
    @apply text-zinc-600;
  }

  .text-dark {
    @apply text-zinc-900;
  }

  .text-link {
    @apply text-blue-600;
  }

  /* SURFACE COLOURS */
  .surface-primary {
    @apply bg-white;
  }

  .surface-secondary {
    @apply bg-gray-100;
  }

  .surface-sub {
    @apply bg-gray-700;
  }

  .surface-black {
    @apply bg-black;
  }

  .surface-white {
    @apply bg-white;
  }

  .surface-nav {
    @apply bg-white;
  }

  .surface-header {
    @apply bg-white;
  }

  .surface-50 {
    @apply bg-gray-50;
  }

  /* BORDER COLOURS */
  .border-primary {
    @apply border-gray-200;
  }

  .border-secondary {
    @apply border-gray-300;
  }

  /** CAROUSEL **/
  .carousel-transition {
    transition:
      opacity 1s ease-in,
      transform 1s cubic-bezier(0.4, 0, 0.2, 1);
    /* border: 2px solid green; */
  }
}
